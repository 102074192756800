import { DeviceUUID } from 'device-uuid';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

export default class JwtUtil {

  static base64url(source) {
    var encodedSource = CryptoJS.enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, "");
    encodedSource = encodedSource.replace(/\+/g, "-");
    encodedSource = encodedSource.replace(/\//g, "_");
    return encodedSource;
  }

  static token(appid) {
    var deviceToken = new DeviceUUID().get();
    var header = {
      alg: "HS256",
      typ: "JWT",
    };

    let jwtToken = null;
    const json = localStorage.getItem("Raw_id");
    if (json) {
      var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
      var encodedHeader = this.base64url(stringifiedHeader);
      var bytes = CryptoJS.AES.decrypt(json, environment.jwtSecret);
      const descryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      const decrypt = JSON.parse(descryptedData);
      var Utc = new Date().toUTCString();
      var parsed = Date.parse(Utc) / 1000;
      var data = {
        ip: decrypt.ip,
        iss: "Web",
        sub: "security",
        "user-agent": typeof navigator !== undefined && navigator?.userAgent,
        device_token: deviceToken,
        city: decrypt.city,
        region: decrypt.region,
        country: decrypt.country,
        loc: decrypt.loc,
        timezone: decrypt.timezone,
        iat: parsed,
        exp: parsed + 600,
        app_id: appid,
        client_type: "Crickslab-Static-Graphics",
      };
      var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
      var encodedData = this.base64url(stringifiedData);
      var token = encodedHeader + "." + encodedData;
      var secret = environment.jwtSecret;
      let signature = CryptoJS.HmacSHA256(token, secret);
      jwtToken = token + "." + this.base64url(signature);
    }

    return jwtToken;
  
  }

}

