import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  
  isLoaded = false;
  ngOnInit() {
    this.getIpInfo();
  }

  getIpInfo() {
    if (!localStorage.getItem("Raw_id")) {
      fetch(environment.ipInfoEndPoint).then((res) => {
        res.json().then((response) => {
          const data = JSON.stringify(response.data);
          var ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(data),
            environment.jwtSecret
          ).toString();
          localStorage.setItem("Raw_id", ciphertext);
          window.location.reload();
        });
      });
    } else {
      this.isLoaded = true;
    }
  }
}
