import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'scorecard',
    loadChildren: () => import('./scorecard/scorecard.module').then(m => m.ScorecardModule)
  },
  {
    path: 'matches',
    loadChildren: () => import('./matches/matches.module').then(m => m.MatchesModule)
  },
  {
    path: 'tournaments',
    loadChildren: () => import('./tournament/tournament.module').then(m => m.TournamentsModule)
  },
  {
    path: 'overlays',
    loadChildren: () => import('./overlays/overlay.module').then(m => m.OverlayModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
