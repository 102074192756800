import * as moment from 'moment-timezone';

export default class Utils {

  static LOAD_MORE = 'Load More';
  static LOADING = 'Loading...';

  static SIGNUP_PROVIDERS: any = {
    cl: 'CRICKSLAB',
    fb: 'FACEBOOK',
    gm: 'GOOGLE'
  };

  static USER_RULES = [
    { id: 1, title: 'Super Admin' },
    { id: 2, title: 'Player' },
    { id: 3, title: 'Umpire' },
    { id: 4, title: 'Match Manager' },
    { id: 6, title: 'Digital Scorer' },
    { id: 7, title: 'League Manager' },
    { id: 9, title: 'Fan' },
    { id: 10, title: 'Organizer / Coordinator' },
    { id: 11, title: 'Commentator' },
    { id: 12, title: 'Association Manager' },
    { id: 13, title: 'Club Manager' },
    { id: 14, title: 'System Agent' },
    { id: 15, title: 'Official Manager' },
    { id: 16, title: 'Broadcast Manager' },
    { id: 17, title: 'Team Owner' },
    { id: 18, title: 'Manual Scorer' },
    { id: 19, title: 'Match Referee' },
    { id: 20, title: 'Field Umpires' },
    { id: 21, title: 'Reserve Umpire' },
    { id: 22, title: 'Photographer' }
  ];

  static SOCIAL_LOGINS: any = {
    success: 'SUCCESS_LOGIN',
    failed: 'FAILED_LOGIN',
  };

  static inquiryTypes: any = [
    { id: 'products', title: 'Contact for products' },
    { id: 'media', title: 'Contact for media inquiry' },
    { id: 'advertisement', title: 'Contact for advertising' },
    { id: 'turkey', title: 'TURNKEY Tournaments' },
    { id: 'service', title: 'Club & Competition Setup Service' },
    { id: 'scoringwidget', title: 'Website Scoring widget' },
    { id: 'whitelabel', title: 'White Label Solution' },
    { id: 'officials', title: 'Become a verified officials' },
    { id: 'talenthunt', title: 'Talent Hunters & Coach Access' },
    { id: 'overlays', title: 'Contact for livestraming overlays' }
  ];

  static getPlayerSettings() {
    const playerSettings: any = [
      { id: '1', title: 'DOB' },
      { id: '2', title: 'BattingType' },
      { id: '3', title: 'BowlingType' },
      { id: '4', title: 'PlayerType' },
      { id: '5', title: 'BIO' }
    ];
    return playerSettings;
  }



  static getClubTypes() {
    const clubs: any = [
      { id: 'School', name: 'School' },
      { id: 'University', name: 'University' },
      { id: 'Club', name: 'Club' }
    ];
    return clubs;
  }

  static getMatchTypes() {
    const MatchType: any = [
      { id: '1', name: 'T10', Value: '10' },
      { id: '2', name: 'T20', Value: '20' },
      { id: '3', name: 'T30', Value: '30' },
      { id: '4', name: 'T40', Value: '40' },
      { id: '5', name: 'One day International', Value: '50' },
      { id: '6', name: 'Test Matches', Value: '> 50' }
    ];
    return MatchType;
  }


  static getRadius() {
    const radius: any = [
      { id: '1', value: '1KM' },
      { id: '2', value: '2KM' },
      { id: '3', value: '3KM' },
      { id: '4', value: '4KM' },
      { id: '5', value: '5KM' }
    ];
    return radius;
  }

  static getleagueType() {
    const LeagueType: any = [
      {
        id: 'league-and-tournament',
        value: 'league-and-tournament',
        name: 'Tournament'
      },
      {
        id: 'series',
        value: 'series',
        name: 'Series'
      },
      {
        id: 'cup',
        value: 'cup',
        name: 'Cup'
      }
    ];
    return LeagueType;
  }

  /**
   *
   * @param type
   */
  static getType(type: string) {
    const types = Utils.getleagueType();
    return types.filter(t => t.id === type)[0].name;
  }

  static getGender() {
    const Gender: any = [
      { id: 1, name: 'Male' },
      { id: 2, name: 'Female' },
      { id: 3, name: 'Mixed' },
    ];
    return Gender;
  }





  static getGrade() {
    const Grade: any = [
      { id: '1', name: 'Grade One' },
      { id: '2', name: 'Grade Two' },
      { id: '3', name: 'Grade Three' },
    ];
    return Grade;
  }

  static getStrategy() {
    const Strategy: any = [
      { id: 1, title: 'Use a Team' },
      { id: 2, title: 'Advance to From Winning' },
      { id: 3, title: 'Advance to from Losing' },
    ];
    return Strategy;
  }

  static searchType() {
    return {
      PLATFORM: 'FROM_PLATFORM',
      ACCOUNT: 'FROM_ACCOUNT'
    };
  }

  static getTimepickerdrop() {
    const locale = 'en'; // or whatever you want...
    const hours = [];
    moment.locale(locale);  // optional - can remove if you are only dealing with one locale
    for (let hour = 0; hour < 24; hour++) {
      for (let minutes = 0; minutes < 4; minutes++) {
        hours.push(
          moment({
            hour,
            minute: 15 * minutes
          }).format('H:mm A ')
        );
      }
    }
    return hours;
  }

  static getDuration() {
    const locale = 'en'; // or whatever you want...
    const duration = [];
    moment.locale(locale);  // optional - can remove if you are only dealing with one locale
    let hour: any;
    let minutes: any;
    for (hour = 0; hour <= 24; hour++) {
      for (minutes = 0; minutes < 4; minutes++) {
        duration.push(hour + ' hour ' + (minutes === 0 ? '00' : 15 * minutes) + ' minutes ');
      }
    }
    return duration;
  }

  static timeConvert(n: number) {
    const num = n;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return rhours + ' hour(s) and ' + rminutes + ' minute(s)';
  }

  /**
   * Get Application Timezone
   */
  static getAppTimeZone() {
    return moment.tz.guess();
  }

  static getofficialTypes() {
    return {
      umpire: 1,
      scorer: 2,
      admin: 3
    };
  }

  static getFormatedDate(date: any) {
    let formatedDate = '';
    if (date !== null && date !== undefined && date !== '') {
      formatedDate = (date.year < 10 ? '0' + date.year : date.year)
        + '-' + (date.month < 10 ? '0' + date.month : date.month) + '-' + (date.day < 10 ? '0' +
          date.day : date.day);
    }
    return formatedDate;
  }

  static getFormatedTime(time: any) {
    const formatedTime: string = (time.hour < 10 ? '0' + time.hour : time.hour) + ':' +
      (time.minute < 10 ? '0' + time.minute : time.minute);

    return formatedTime;
  }

  /**
   * Get Current Country ID.
   * @param countries
   */
  static currentCountry(countries: any[]) {
    const location = JSON.parse(localStorage.getItem('location'));
    let userCountry: any;
    if (location !== null) {
      userCountry = countries.filter(c => c.iso === location.country)[0];
    } else {
      userCountry = countries.filter(c => c.id === 365)[0];
    }
    return {
      country: userCountry,
      location: location
    };
  }

  /**
   * Get Current Country ID.
   * @param countries
   */
  static getCountryCode() {
    const location = JSON.parse(localStorage.getItem('location'));
    let code = 'pk';
    if (location !== null) {
      code = location.country;
    }
    return code;
  }

  static fancyTimeFormat(time: any) {
    let hrs = ~~(time / 3600);
    let mins = ~~((time % 3600) / 60);
    let secs = ~~time % 60;

    // Output like '1:01' or '4:03:59' or '123:03:59'
    let ret = '';

    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
  }

  static getRating(rating: any) {
    let html = '';
    const ratingValue = parseFloat(rating);
    const rounded = (ratingValue || 0);

    const decimal = ratingValue - rounded;

    for (let rated = 1; rated <= ratingValue; rated++) {
      if (rated <= ratingValue) {
        html += '<i class="text-warning fa fa-star"></i>';
      }
    }

    if (decimal) {
      html += '<i class="text-warning fa fa-star-half-o" aria-hidden="true"></i>';
    }

    for (let notRated = ratingValue; notRated < 5; notRated++) {
      if (!decimal) {
        html += '<i class="fa fa-star"></i>';
      }
    }

    return html;
  }

  static getManagerActions() {
    return {
      title: 'League Manager',
      description: 'Manage your company\'s clubs, competitions & tournaments, teams, players, officials and rules.',
      actions: [
        {
          title: 'Competitions',
          link: ['/account/competitions'],
          icon: './assets/img/home/club_selected.png',
          color: '#fff',
          class: 'col-lg-6 col-sm-6'
        },
        {
          title: 'Matches',
          link: ['/account/matches'],
          icon: './assets/img/scoring_select_icon.png',
          color: '#fff',
          class: 'col-lg-3 col-sm-3'
        },
        {
          title: 'Match Rules',
          link: ['/account/rules'],
          icon: './assets/img/home/quick_chat.png',
          color: '#fff',
          class: 'col-lg-3 col-sm-3'
        },
        {
          title: 'Teams',
          link: ['/account/teams'],
          icon: './assets/img/fans_select_icon.png',
          color: '#fff',
          class: 'col-lg-3 col-sm-3'
        },
        {
          title: 'Member Players',
          link: ['/account/players'],
          icon: './assets/img/home/player_selected.png',
          color: '#fff',
          class: 'col-lg-3 col-sm-3'
        },
        {
          title: 'Clubs',
          link: ['/account/clubs'],
          icon: './assets/img/home/school_selected.png',
          color: '#fff',
          class: 'col-lg-3 col-sm-3'
        },
        {
          title: 'Officials',
          link: ['/account/officials'],
          icon: './assets/img/home/coach_selected.png',
          color: '#fff',
          class: 'col-lg-3 col-sm-3'
        }
      ]
    }
  }

  static getOfficialActions() {
    return {
      title: 'Official Profile',
      description: 'See all your previous and upcoming matches and competitions where you are a Crickslab official.',
      actions: [
        {
          title: 'My Competitions',
          link: ['/account/official-detail/competitions'],
          icon: './assets/img/profile/create_tournament.png',
          color: '#fff'
        },
        {
          title: 'My Matches',
          link: ['/account/official-detail/matches'],
          icon: './assets/img/profile/create_match.png',
          color: '#fff'
        }
      ]
    }
  }

  static getPlayerActions() {
    return {
      title: 'Player Profile',
      description: 'Checkout your matches and competitions as well as clubs and teams that you are affiliated with on Crickslab',
      actions: [
        {
          title: 'My Competitions',
          link: ['/account/player-detail/competitions'],
          icon: './assets/img/profile/create_tournament.png',
          color: '#fff'
        },
        {
          title: 'My Clubs',
          link: ['/account/clubs'],
          icon: './assets/img/profile/club_icon.png',
          color: '#fff'
        },
        {
          title: 'My Teams',
          link: ['/account/player-detail/teams'],
          icon: './assets/img/profile/club_icon.png',
          color: '#fff'
        },
        {
          title: 'My Matches',
          link: ['/account/player-detail/matches'],
          icon: './assets/img/profile/create_match.png',
          color: '#fff'
        }
      ]
    }
  }

  static getMatchesMeta(type: string) {
    let metaData = {
      title: 'Crickslab - All Matches | Match Central | Live Cricket Scores, Live Streaming, Match Scorecard, Ball by Ball Commentary',
      description: 'See the live scores from every match played using Crickslab Live Scoring App. Get Live Cricket Scores, Ball by Ball Commentary, Live Video Streaming, Match Facts and insights just like international cricket.'
    };

    if (type === 'in-progress') {
      metaData = {
        title: 'Crickslab - Live Matches | Match Central | Live Cricket Scores, Live Streaming, Match Scorecard, Ball by Ball Commentary',
        description: 'See the live scores from current matches in progress on the Crickslab Live Scoring App. Get Live Cricket Scores, Ball by Ball Commentary, Live Video Streaming, Match Facts and insights. Score your next match for free with Crickslab.'
      };
    } else if (type === 'schedules') {
      metaData = {
        title: 'Crickslab - Upcoming Cricket Matches | Match Central | Live Cricket Scores, Live Streaming, Match Scorecard, Ball by Ball Commentary',
        description: 'Checkout every upcoming cricket match to be scored on the Crickslab Live Scoring App. Join the match, follow the players, get match notifications, search upcoming matches and more. Running your own cricket tournament? Try Crickslab for your live cricket scoring.'
      };
    } else if (type === 'completed') {
      metaData = {
        title: 'Crickslab - Recent Matches | Match Central | Live Cricket Scores, Live Streaming, Match Scorecard, Ball by Ball Commentary',
        description: 'See the live scores from recent matches played with the Crickslab Live Scoring App. See all the action including player and team Scores, Ball by Ball Commentary, Live Video Streaming, Match Facts, Man of the Match, Stats and insights. '
      };
    }

    return metaData;
  }

  static getTournamentsMeta(type: string) {
    let metaData = {
      title: 'Cricket Competitions | Cricket Tournaments, Leagues & Series hosted by Crickslab',
      description: 'Find live, upcoming and recent domestic cricket tournaments right here on Crickslab. Join a tournament or Create your own. Get updated with Professional Domestic tournaments, School cricket tournaments, Youth cricket tournaments, corporate cricket tournaments and more on Crickslab.'
    };

    if (type === 'ongoing') {
      metaData = {
        title: 'Ongoing Cricket Competitions | Cricket Tournaments, Leagues & Series hosted by Crickslab',
        description: 'Checkout the ongoing domestic cricket tournaments happening right now on Crickslab. Get all the action from every match in the tournament as well as scorecards and live match information. Get updated with Professional Domestic tournaments, School cricket tournaments, Youth cricket tournaments, corporate cricket tournaments and more on Crickslab.'
      };
    } else if (type === 'recent') {
      metaData = {
        title: 'Completed Cricket Competitions | Cricket Tournaments, Leagues & Series hosted by Crickslab',
        description: 'Checkout the completed domestic cricket tournaments hosted by Crickslab. Get all the action from every match in the tournament as well as scorecards and live match information. Get updated with Professional Domestic tournaments, School cricket tournaments, Youth cricket tournaments, corporate cricket tournaments and more on Crickslab.'
      };
    }

    return metaData;
  }

  static downLoadFile(blob: any, name: string) {
    const objectUrl = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }

  static setCssClass(ball: any, key: string, type: string): void {
    let mapping = {};
    if (type === 'scorecard') {
      mapping = {
        'normal-runs': 'run-sec',
        'dot-ball': 'dot-ball',
        'extra-runs': 'run-sec line-height1',
        'wicket-down': 'wicket-ball',
        'highest-runs': 'four-ball'
      };
    } else {
      mapping = {
        'normal-runs': 'Rajitha-wa Rajitha-wa-tree',
        'dot-ball': 'Rajitha-wa Rajitha-wa-tree',
        'extra-runs': 'Rajitha-wa Rajitha-wa-tree line-hight-1',
        'wicket-down': 'Rajitha-wa',
        'highest-runs': 'Rajitha-wa Rajitha-wa-sec'
      };
    }

    ball[key] = mapping[ball[key]];
  }

}
