import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import Utils from '../utils/utils';
import JwtUtil from '../utils/jwtUtil';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';


@Injectable()
export class HeadersInterceptor implements HttpInterceptor {

    constructor(@Inject(PLATFORM_ID) private platformId: object) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
         // add authorization header with jwt token if available
         const location = isPlatformBrowser(this.platformId) ? localStorage.getItem('location') : null;
         request = request.clone({
            setHeaders: {
                'app-tz': Utils.getAppTimeZone(),
                'app-lang': 'en-us',
                'app-id': environment.appId,
                'handshake-token': JwtUtil.token(environment.appId),
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImRmZTVlZjBiNjlmOGRkZTE5NWZkZTk4Mzc4N2ZkY2MwZDdlMWIzNjQ2OGEzNDIxZmNmZjA3ZmRlYjk3M2FlMGIwMTA5ODc3ODYxYWQ1MzVkIn0.eyJhdWQiOiIyIiwianRpIjoiZGZlNWVmMGI2OWY4ZGRlMTk1ZmRlOTgzNzg3ZmRjYzBkN2UxYjM2NDY4YTM0MjFmY2ZmMDdmZGViOTczYWUwYjAxMDk4Nzc4NjFhZDUzNWQiLCJpYXQiOjE2NTgxMTk4OTUsIm5iZiI6MTY1ODExOTg5NSwiZXhwIjoxNjU4MjA2Mjk1LCJzdWIiOiIxNDgiLCJzY29wZXMiOlsiKiJdfQ.hLf4_25ama47_nSJDFF-vTHr4XhVUgB5jvFqWCJwibswGHvCc7F3spCtmjSeEIYQPZ6X6OYSaao9360yJ8nNfa7Am_cc-QFc_PZLqfoXICh-n6l3aQZHKIaa-Z8QgbIy2GD23fBPOLt5hio9zcI2DnHnReuvYpUz9sJ_nCkXcOvXK-AunTYkbLfa8HDlndTkQU5BO02M3JSKUfJcZrShA1ZE7p2obyG3S3cI0HhKNVMZzFfZpZSFYNlCE9sZ6PDc0KZPuKNO26Vn_baYMGBKFbtk_fNmjzwVQZ4G3AH6lujaTbeBUE0ZmwT3dX0tS0mgh33T9SOHaOnQLwFgPljXzuJUibPZ2tx38r_KoWNhuG7pHBoyoy-4B9ISWU4vG79XOVlM0iXyUaNAhJPdPOflzjyvUfKrAcTvO9jk6Ib5sp4v06wr8j4Dnda9KaYwBWMoI4Yn5jXstb90ZLPeCg6oZERsyJSYY_1PszVCweEuGGc0OrQrhQHZyLN_L2acipmwf0O5ovoKLr2TEapJJMNdtnZ9ylzdpBrBJWVwl4-VuQ5x_OWrKBXvCQsHWmrJLZJaCqEr1hIio2cGTl0L_MSIcQqc_hDB5BEQAOUF3LqyF3lnu-vyYY74ttfnf_kKfh5hoICRCnW1-pM7y6X_QLsXzSbit-VnOiQSA2dieAbFNZI'
            }
        });
        return next.handle(request);
    }
}
